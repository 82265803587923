/* eslint-disable indent */
import './PuxVideo.scss'

import FsLightbox from 'fslightbox-react'
import React, { FunctionComponent, useState } from 'react'

import PuxPicture, { PuxPictureType } from '../content/mediaImage/PuxPicture'
import PuxIconBuilder from '../PuxIconBuilder/PuxIconBuilder'
import PuxStructuredData from '../PuxStructuredData/PuxStructuredData'

export interface PuxVideoType {
  videoYTID: {
    html: string
  }
  videoThumbnail: PuxPictureType
  videoStructuredDataName: string
  videoStructuredDataDescription: string
  videoStructuredDataUploadDate: string
  videoStructuredDataContentUrl: string
  videoLabel?: string
  videoTitle?: string
  videoPerex?: string
}

const getYTSubtitlesLocale = (): string => {
  const locale = process.env.LOCALE ?? `en`

  switch (locale) {
    case `br`:
      return `pt-BR`
    case `jp`:
      return `ja`
    case `kr`:
      return `ko`
    default:
      return locale
  }
}

const PuxVideo: FunctionComponent<PuxVideoType> = (props) => {
  const [toggler, setToggler] = useState(false)

  const rootClass = `video`

  const classes = `${rootClass} ${props.videoThumbnail ? `has-thumbnail` : ``}`

  const yTLocale = getYTSubtitlesLocale()
  const videoSources = []
  videoSources.push(
    <iframe
      loading='lazy'
      style={{
        width: `90vw`,
        height: `${(90 / 16) * 9}vw`,
      }}
      src={`https://www.youtube-nocookie.com/embed/${props.videoYTID?.html}?autoplay=1&modestbranding=1&rel=0&iv_load_policy=3&cc_load_policy=1&cc_lang_pref=${yTLocale}`}
      frameBorder='0'
      allow='autoplay; encrypted-media;'
    ></iframe>
  )

  const structuredData = {
    '@context': `https://schema.org`,
    '@type': `VideoObject`,
    name: props.videoStructuredDataName,
    description: props.videoStructuredDataDescription,
    uploadDate: props.videoStructuredDataUploadDate, //Date format `2016-03-31T08:00:00+08:00`
    thumbnailUrl: [
      props.videoThumbnail?.resizePaths ?? ``,
      props.videoThumbnail?.resizePathsTablet ?? ``,
      props.videoThumbnail?.resizePathsMobile ?? ``,
    ],
    contentUrl: props.videoStructuredDataContentUrl,
  }
  if (props.videoYTID?.html) {
    return (
      <div className={classes}>
        <div className='video-thumbnail' onClick={() => setToggler(!toggler)}>
          <PuxPicture {...props.videoThumbnail} />
          <PuxIconBuilder icon='PlayIcon' />
        </div>
        {props.videoLabel && (
          <div className='video-label'>{props.videoLabel}</div>
        )}
        {props.videoTitle && (
          <div className='video-title' onClick={() => setToggler(!toggler)}>
            {props.videoTitle}
          </div>
        )}
        {props.videoPerex && (
          <div
            className='video-perex'
            dangerouslySetInnerHTML={{ __html: props.videoPerex }}
          ></div>
        )}
        <FsLightbox
          toggler={toggler}
          sources={(videoSources as unknown) as string[]}
        />
        {props.videoStructuredDataName &&
          props.videoStructuredDataDescription &&
          props.videoStructuredDataUploadDate &&
          props.videoStructuredDataContentUrl && (
            <PuxStructuredData structuredData={structuredData} />
          )}
      </div>
    )
  } else {
    return null
  }
}

export default PuxVideo
